import React, {Component} from "react";
import {BackButton} from "../../global/tiger-button/BackButton";
import {GetClusterWithProducts} from "./ClusterService";
import logo from "../../../assets/images/loader_bg.png";
import "./ClusterDetails.css";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import GlobalConstants from "../../config/GlobalConstants";
import ClusterProduct from "./ClusterProduct";
import {TigerButton} from "../../global/tiger-button/TigerButton";
import {GetProfilePreferences, UpdatePreferences} from "../profiles/ProfileService";
import {Spinner} from "react-bootstrap";
import {PaginationHelp} from "../../global/pagination-help/PaginationHelp";
import {GetSeriesByClusterId} from "../series/SeriesService";
import googleTagManagerWrapper from "../../api/GoogleTagManagerWrapper";

class ClusterDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            updatingPreferences: false,
            page : 0,
            totalPages : 10,
            cluster: {
                products: [],
                cover: logo
            },
            clusterId: this.props.match.params.id,
            type: null,
            profileLink: null,
            accountId: null,
            profileId: null,
            productId: null,
            redirectUrl: null,
            isSelected: false
        };
    }

    async componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                profileLink: this.props.location.state.profileLink != null ? this.props.location.state.profileLink : null,
                profileId: this.props.location.state.profileId != null ? this.props.location.state.profileId : null,
                isSelected: this.props.location.state.isSelected != null ? this.props.location.state.isSelected: false,
                productId: this.props.location.state.productId != null ? this.props.location.state.productId : null,
                type: this.props.location.state.type != null ? this.props.location.state.type : null,
                source: this.props.location.state.source != null ? this.props.location.state.source : 'preferences',
            }, () => this.loadClusterOrRedirectToSeries());
        } else {
            await this.loadClusterOrRedirectToSeries();
        }
        window.scrollTo(0, 0);
    }

    async loadClusterOrRedirectToSeries(page = 0) {
        if (this.state.clusterId) {
            this.setState({
                loading: true,
            });

            if (this.state.type === 'HERO') { //topics has no assigned series
                const response = await GetSeriesByClusterId(this.state.clusterId);
                if (response.error === true) {
                    //ignore an error while retrieving series
                    console.log("error while retrieving series by cluster id " + this.state.clusterId);
                } else if (response.result?.length > 0) {
                    let series = response.result[0];
                    let url;
                    if (series.slug) {
                        url = '/series/' + series.slug + "/" + series.id;
                    } else {
                        url = '/series/' + series.id;
                    }
                    this.setState({
                        loading: false,
                        redirectUrl: url
                    }, () => {
                        if (url) {
                            this.props.history.replace({
                                pathname: this.state.redirectUrl,
                                state:  {
                                    clusterId: this.state.clusterId,
                                    accountId: this.state.accountId,
                                    profileId: this.state.profileId,
                                    isSelected: this.state.isSelected,
                                    profileLink: this.state.profileLink,
                                }
                            });
                        }
                    });
                    return;
                }
            }


            GetClusterWithProducts(this.state.clusterId, page).then((cluster) => {
                //We only want to show clusters with state = 'ACTIVE'
                if (cluster != null && cluster.visibility) {
                    this.setState({
                        cluster: {
                            ...cluster,
                            cover: (cluster._links?.icon?.href) ? cluster._links.icon.href : logo
                        },
                        totalPages : cluster.totalPages,
                        page: cluster.number
                    });

                    //Window title
                    document.title = this.state.cluster.name + " :: tiger.media";

                } else {
                    this.setState({
                        cluster: {products: [], cover: logo}
                    });

                    //Window title
                    document.title = "Held oder Welt nicht gefunden";
                }

                this.setState({
                    loading: false,
                });

            });
        } else {
            this.setState({
                cluster: this.state.cluster,
            });

            //Window title
            document.title = this.state.cluster.name + " :: tiger.media";
        }
    }

    async toggleCluster(cluster) {
        if (this.state.profileId) {
            //Load the clusters
            this.setState(prevState => ({...prevState, updatingPreferences: true}));
            let response = await GetProfilePreferences(this.state.profileId);
            if (!response.error) {
                let clusters = response.result && response.result._embedded && response.result._embedded.clusters;
                if (clusters === undefined) {
                    clusters = [];
                }
                const index = clusters.map(i => Number(i.id)).indexOf(Number(cluster.id));
                if (index > -1) {
                    clusters.splice(index, 1);
                    googleTagManagerWrapper.removeCluster(this.state.cluster.name);
                } else {
                    clusters.push(cluster);
                    googleTagManagerWrapper.addCluster(this.state.cluster.name);
                }
                UpdatePreferences(this.state.profileId, clusters).then(r => {
                    this.setState(prevState => ({...prevState, isSelected: !this.state.isSelected, updatingPreferences: false}));
                    }).catch(r => this.setState(prevState => ({...prevState, updatingPreferences: false})));
            } else {
                this.setState(prevState => ({...prevState, updatingPreferences: false}));
            }
        }
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
            hash: this.state.source,
            state: this.state.profileLink
        });
    }

    handlePageClick = (event) => {
        console.log(`User requested page number ${event.selected}`);
        this.setState(prevState => ({...prevState, page: event.selected}), () => this.loadClusterOrRedirectToSeries(event.selected));
    };

    render() {
        const cluster = this.state.cluster;

        if (this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br/>
                    <br/>
                    <br/>
                    <TigerSpinner/>
                    <br/>
                </div>
            )
        }

        return (
            cluster ?
                <div className="content-box">
                    {this.state.profileLink &&
                        <BackButton onClick={() => this.closeView()}/>
                    }
                    <div className="content-header">{this.state.cluster.name}</div>
                    <div className="mb-4">
                        <div className="cluster-details-wrap">
                            <div className="cover-container">
                                <div className="cluster-cover" style={{border: "none"}}>
                                    <img src={this.state.cluster.cover} alt="cover" className="cover-img"/>
                                </div>
                                {this.state.profileId != null &&
                                    <>
                                        {this.state.isSelected ?
                                            <TigerButton variant="red" type="button" onClick={() => this.toggleCluster(cluster)}>{this.state.updatingPreferences ? <Spinner animation="border" /> : <>{Strings.PROFILE_CLUSTER_REMOVE}</>}</TigerButton>
                                        :
                                            <TigerButton variant="green" type="button" onClick={() => this.toggleCluster(cluster)}>{this.state.updatingPreferences ? <Spinner animation="border" /> : <>{Strings.PROFILE_CLUSTER_ADD}</>}</TigerButton>
                                        }
                                    </>
                                }
                            </div>
                            <div className="w-100 cluster-product-list">
                                {this.state.cluster.products.map(p => (
                                    <div id={"product_" + p.id} key={p.id}>
                                        <ClusterProduct product={p}
                                                        history={this.props.history}
                                                        clusterId={this.state.cluster.id}
                                                        profileLink={this.state.profileLink}
                                                        profileId={this.state.profileId}
                                                        isSelected={this.state.isSelected}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <PaginationHelp
                            page={this.state.page}
                            totalPages={this.state.totalPages}
                            handlePageClick={this.handlePageClick}
                        />

                    </div>

                </div>
                :
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "25px"}}>
                    <br/>
                    <img src={logo} alt="tigermedia logo" width={100}/>
                    <br/>
                    <br/>
                    <span style={{fontSize: "40px"}}>{Strings.PRODUCT_DETAILS_ERROR_TITLE}</span>
                    <br/>
                    <br/>
                    {Strings.PRODUCT_DETAILS_ERROR_MESSAGE} <a
                    href={"https://tiger.media/"}>{Strings.PRODUCT_DETAILS_ERROR_LINK}</a>
                </div>
        );
    }
}

export default ClusterDetails;
