import "./RegisterForm.css";
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Strings from "../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {useHistory} from "react-router";
import GlobalConstants from "../config/GlobalConstants";
import {authCheck} from "../util/authCheck";
import {Link} from "react-router-dom";
import {TigerButton} from "../global/tiger-button/TigerButton";
import {InfoModal} from "../global/ConfirmationModal";
import {CreateResource} from "../api/SpineClient";
import {FcApproval} from "react-icons/all";
import {Helmet} from "react-helmet";
import googleTagManagerWrapper from "../api/GoogleTagManagerWrapper";

const ERROR_CODE_DUPLICATE_EMAIL = "400-03";
const ERROR_CODE_EMAIL_OF_INACTIVE_ACCOUNT = "400-04";
const ERROR_CODE_PASSWORT_TOO_SHORT = "400-01";

export default function RegisterForm({ setToken }) {
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [submitDisable, setSubmitDisable] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [unknownError, setUnknownError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        authCheck() && history.push("/" + GlobalConstants.APP_PATH + "dashboard");
    }, [history]);

    const setField = (field, value, isValid) => {
        setPasswordError(false);
        setUnknownError(false);
        setEmailError(false);

        setForm({
            ...form,
            [field]: value
        });

        if (!isValid) {
            setErrors({
                ...errors,
                [field]: true
            });
        } else {
            setErrors({
                ...errors,
                [field]: false
            });
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmitDisable(true);

        let hasErrors = !Object.values(errors).every(x => x === false);
        if (!hasErrors) {
            setSubmitted(true);

            let {email, password, newsletter} = form;
            let newUser = {
                'email': email.trim(),
                'password': password,
                'newsletter': !!newsletter,
                'registerDeviceId': 'customer-ui-service'
            };

            let response = await CreateResource('api/register', newUser);

            if (!response.error) {
                let user_id = response.result.id;
                googleTagManagerWrapper.userInfo(user_id)
                googleTagManagerWrapper.registration(user_id);
                history.push("/" + GlobalConstants.APP_PATH + "login?new=true")
            } else {
                if (response.message != null && (response.message.indexOf(ERROR_CODE_DUPLICATE_EMAIL) > -1 || response.message.indexOf(ERROR_CODE_EMAIL_OF_INACTIVE_ACCOUNT) > -1)) {
                    setEmailError(true);
                    setErrors({...errors, email: true});
                } else if (response.message != null && response.message.indexOf(ERROR_CODE_PASSWORT_TOO_SHORT) > -1) {
                    setPasswordError(true);
                    setErrors({...errors, password: true});
                } else {
                    setUnknownError(true);
                }
            }

            setSubmitDisable(false);
        }
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"Registrierung :: tiger.media"}</title>
                <meta name="description" content={'Registriere deinen tigermedia-Account, um die tigertones-App und deine tigerbox nutzen zu können'} />
                <meta name="keywords" content={'tigertones,registrierung,anlegen,tigerbox,account,konto,tigermedia'} />
                <link rel="canonical" href={"https://my.tiger.media/register"}/>
            </Helmet>

            <div className="register-form-header">
                <p style={{fontSize: "50px", fontWeight: "bold"}}>{Strings.REGISTER_FORM_TITLE}</p>
                <p style={{fontSize: "20px"}}>{Strings.REGISTER_FORM_INFO}</p>
            </div>

            <div className="register-form">
                <Form className="text-left" onSubmit={handleSubmit}>
                    {/* Email */}
                    <Form.Group className="mb-3" controlId="registerFormEmail">
                        <Form.Label className="auth-label">{Strings.REGISTER_FORM_EMAIL}</Form.Label>
                        <Form.Control
                            type="email"
                            required
                            isInvalid={errors.email}
                            onChange={e => setField('email', e.target.value, e.target.checkValidity())}
                        />

                        <Form.Control.Feedback type="invalid">
                            {emailError ? Strings.REGISTER_EMAIL_IN_USE : Strings.AUTHENTICATION_ERROR_INVALID_EMAIL}
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Password */}
                    <Form.Group className="mb-3" controlId="registerFormPassword">
                        <Form.Label className="auth-label" style={{width: "100%"}}>
                            {Strings.REGISTER_FORM_PASSWORD}
                            <span style={{
                                fontSize: "18px",
                                fontWeight: "normal",
                                color: '#ababab',
                                marginLeft: "5px"
                            }}>{Strings.REGISTER_FORM_PASSWORD_REQUIREMENTS}</span>
                            <span style={{
                                fontSize: "18px",
                                float: "right",
                                cursor: "pointer",
                                color: '#ce023e',
                                marginTop: "5px",
                                fontWeight: "normal"
                            }}
                                  onClick={() => setShowPassword(!showPassword)}>{showPassword ? Strings.RESET_PASSWORD_HIDE : Strings.RESET_PASSWORD_SHOW}</span>
                        </Form.Label>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            required
                            isInvalid={errors.password}
                            onChange={e => setField('password', e.target.value, (e.target.value.length >= 8 && e.target.value.length <= 50))}
                        />

                        <Form.Control.Feedback type="invalid">
                            <span
                                style={{fontSize: "16px"}}>{passwordError ? Strings.REGISTER_PASSWORT_TO_SHORT : Strings.RESET_PASSWORD_ERROR_LENGTH}</span>
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* Password Confirmation */}
                    <Form.Group className="mb-3" controlId="registerFormPasswordConfirmation">
                        <Form.Label className="auth-label">{Strings.REGISTER_FORM_PASSWORD_CONFIRMATION}</Form.Label>
                        <Form.Control
                            type="password"
                            required
                            isInvalid={errors.confirmation}
                            onChange={e => setField('confirmation', e.target.value, (e.target.value === form.password))}
                        />

                        {(form.confirmation != null && form.confirmation !== "" && form.confirmation !== form.password) &&
                        <Form.Control.Feedback type="invalid">
                            <span style={{fontSize: "16px"}}>{Strings.RESET_PASSWORD_ERROR_MATCH}</span>
                        </Form.Control.Feedback>
                        }
                    </Form.Group>

                    <div>
                        <div className={"round"}>
                            <input name="terms" id="terms" type="checkbox" value={form.terms} onChange={(e) => {
                                setField('terms', !form.terms, e.target.checked);
                                if (e.target.checked)
                                {
                                    setSubmitDisable(false);
                                }
                                else
                                {
                                    setSubmitDisable(true);
                                }
                            }}/>
                            <label htmlFor="terms"></label>
                        </div>
                        <label className="roundlabel registerCheckboxLabel" htmlFor="conditions">* Hiermit akzeptiere
                            ich
                            die <a href="https://tiger.media/policies/terms-of-service" className="black" target="_blank"
                                   rel="noopener noreferrer">AGB</a></label>
                    </div>
                    <div>
                        <div className={"round"}>
                            <input name="newsletter" id="newsletter" type="checkbox" value={form.newsletter}
                                   onChange={() => setField('newsletter', !form.newsletter, true)}/>
                            <label htmlFor="newsletter"></label>
                        </div>
                        <label className="roundlabel registerCheckboxLabel"
                               htmlFor="newsletter">{Strings.REGISTER_FORM_CHECKBOX_NEWSLETTER}</label>
                    </div>

                    <TigerButton className="w-100" style={{marginTop: "15px"}} disabled={submitDisable} variant="red">
                        {(submitDisable && submitted) ?
                            <Spinner animation="border"/>
                            : Strings.REGISTER_FORM_REGISTER_BUTTON}
                    </TigerButton>
                </Form>

                <p style={{marginTop: "30px", fontSize: "20px"}}>
                    {Strings.REGISTER_FORM_LOGIN_HINT}
                    <Link style={{fontWeight: "bold", color: "#333", textDecoration: "underline", marginLeft: "5px"}}
                          to={"/" + GlobalConstants.APP_PATH + "login"}>
                        {Strings.REGISTER_FORM_LOGIN_LINK}
                    </Link>
                </p>
            </div>

            {/* ERROR MODAL */}
            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={Strings.REGISTER_UNKNOWN_ERROR_BODY}
                show={unknownError}
                onHide={() => setUnknownError(false)}
            />

            <InfoModal
                title={<div style={{float: "right"}}><FcApproval size={40}/> {Strings.REGISTER_SUCCESS_TITLE} </div>}
                text={Strings.REGISTER_SUCCESS_INFO}
                show={showSuccessModal}
                onHide={() => {
                    setShowSuccessModal(false);
                    history.push("/" + GlobalConstants.APP_PATH + "dashboard");
                }}
            />
        </>
    )
}


RegisterForm.propTypes = {
    setToken: PropTypes.func.isRequired
}