import gtmw from "./GoogleTagManagerWrapper";

class ConsentHandler {
    getCookieByName(name) {
        const allCookies = document.cookie.split("; ");
        const cookie = allCookies.find((c) => c.startsWith(`${name}=`));
        if (cookie) {
            try {
                return JSON.parse(decodeURIComponent(cookie.split("=")[1]));
            } catch {
                return null;
            }
        }
        return null;
    }

    sendDefaultConsent() {
        const defaultConsent = {
            ad_storage: "denied",
            ad_user_data: "denied",
            ad_personalization: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "granted",
            wait_for_update: 500,
        };
        gtmw.sendDefaultConsentSignal(defaultConsent);
    }

    getConsentStatusFromCookie() {
        const consentCookie = this.getCookieByName("_tracking_consent");
        if (!consentCookie || !consentCookie.con?.CMP) {
            return {
                ad_storage: "denied",
                ad_user_data: "denied",
                ad_personalization: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                personalization_storage: "denied",
                security_storage: "granted",
            };
        }

        const CMP = consentCookie.con.CMP;

        return {
            ad_storage: CMP.m === "1" ? "granted" : "denied",
            ad_user_data: CMP.m === "1" ? "granted" : "denied",
            ad_personalization: CMP.m === "1" ? "granted" : "denied",
            analytics_storage: CMP.a === "1" ? "granted" : "denied",
            functionality_storage: CMP.s === "1" ? "denied" : "denied", // Defaults to denied for now
            personalization_storage: CMP.p === "1" ? "granted" : "denied",
            security_storage: "granted",
        };
    }

    sendUpdatedConsent() {
        const consentStatus = this.getConsentStatusFromCookie();

        const allGranted = [
            consentStatus.ad_storage,
            consentStatus.ad_user_data,
            consentStatus.ad_personalization,
            consentStatus.analytics_storage,
            consentStatus.personalization_storage,
        ].every((value) => value === "granted");

        consentStatus.functionality_storage = allGranted ? "granted" : "denied";

        gtmw.updateConsent(consentStatus);
    }

    init() {
        this.sendDefaultConsent();
        const consentStatus = this.getConsentStatusFromCookie();
        if (consentStatus) {
            this.sendUpdatedConsent();
        }
    }
}

export default new ConsentHandler();
