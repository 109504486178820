import React, {useEffect, useState} from "react";
import './../Purchase.scss';
import {useHistory} from "react-router";
import PurchaseStatus from "../PurchaseStatus";
import Strings from "../../../../config/Strings";
import GlobalConstants from "../../../../config/GlobalConstants";
import {authCheck} from "../../../../util/authCheck";
import {TigerButton} from "../../../../global/tiger-button/TigerButton";
import {Spinner} from "react-bootstrap";
import {ConfirmationModal, InfoModal} from "../../../../global/ConfirmationModal";
import {ConvertFeePeriodUnitName, transformStringToReadableDate} from "../../../../util/ConverterUtil";
import * as billwerkJSWrapper from './../../../../api/BillwerkJSWrapper.js';
import {CreateResource, GetFilteredResources, GetResource, SendTeamsNotification} from "../../../../api/SpineClient";
import {TigerSpinner} from "../../../../global/tiger-spinner/TigerSpinner";

export default function SubscriptionPurchaseConfirmation() {

    const [priceData, setPriceData] = useState(null);
    const [billwerkProduct, setBillwerkProduct] = useState(null);
    const [product, setProduct] = useState(null);
    const [trialInformation, setTrialInformation] = useState(null);
    const [hasTrial, setHasTrial] = useState(true);
    const [agb, setAgb] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [showLoginHint, setShowLoginHint] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const [paymentErrorMessage, setPaymentErrorMessage] = useState(null);
    const [orderPreview, setOrderPreview] = useState(null);
    const [component, setComponent] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [email, setEmail] = useState(null);
    const [purchaseData, setPurchaseData] = useState(null);
    const [firstPaymentDate, setFirstPaymentDate] = useState(null);
    const [feePeriodUnitName, setFeePeriodUnitName] = useState(null);

    const history = useHistory();

    document.title = Strings.PURCHASE_SUBSCRIPTION_BROWSER_TAB;

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let email = JSON.parse(sessionStorage.getItem('email'));
        setEmail(email);
        let purchaseData = JSON.parse(sessionStorage.getItem('ls.purchase')) != null ?
            JSON.parse(sessionStorage.getItem('ls.purchase')) : {payment: {channelSecrets: {}}, contact: {}};
        setPurchaseData(purchaseData);

        if (purchaseData == null && queryParams.get("paymentFinished") !== "1") {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
            return null;
        }

        if (!authCheck() || email == null) {
            purchaseData.maxStep = 1;
            sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));
            history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/authenticate");
            return null;
        } else if (queryParams.get("error") != null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
        } else if (queryParams.get("paymentFinished") != null && queryParams.get("paymentFinished") === "1") {
            //Finalize the payment
            const onFinalizeSuccess = () => {
                if (purchaseData.selectedColor) {
                    window.location.href = "https://info.tiger.media/dein-kauf-war-erfolgreich/";
                } else {
                    window.location.href = "https://tiger.media/pages/vielen-dank-fur-deinen-kauf";
                }
            };

            const onError = (...args) => {
                SendTeamsNotification("finalize", purchaseData.orderId, purchaseData.planVariantId, args).then(r => r);
                setPaymentError(true);
            }
            billwerkJSWrapper.finalize(onFinalizeSuccess, onError);

            return null;
        }

        if (purchaseData == null) {
            history.push("/" + GlobalConstants.APP_PATH + "purchase");
            return null;
        }

        const loadProduct = async (planVariantId) => {
            setLoading(true);
            let p = await GetResource('api/purchases/products/search/by-planvariant-id?planVariantId=' + planVariantId,
                'application/json', {}, GlobalConstants.BILL_HOST);
            setLoading(false);
            if (p.error || p.result == null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=4");
                return;
            }

            return p.result;
        }

        const loadSubscriptionContracts = async () => {
            setLoading(true);
            let c = await GetFilteredResources('api/purchases/customer/contracts', {subscriptionsOnly: true}, 'application/json', GlobalConstants.BILL_HOST);
            setLoading(false);
            if (c.error || c.result == null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=5");
                return;
            }

            return c.result;
        }

        const loadBillwerkProduct = async (planVariantId) => {
            setLoading(true);
            let p = await GetResource('api/purchases/billwerk/product/search/by-planvariant-id?planVariantId=' + planVariantId,
                'application/json', {}, GlobalConstants.BILL_HOST)
            setLoading(false);
            if (p.error || p.result == null) {
                history.push("/" + GlobalConstants.APP_PATH + "purchase/error?code=6");
                return;
            }

            return p.result;
        }

        const previewOrder = async () => {

            //Create the request for the purchase service
            if (!purchaseData || !purchaseData.planVariantId) {
                return;
            }
            let request = {
                planVariantId: purchaseData.planVariantId,
                Cart: {
                    PlanVariantId: purchaseData.planVariantId,
                    MeteredUsages: (!purchaseData.selectedColor ? [] : [{
                        ComponentId: purchaseData.componentId,
                        Quantity: 1,
                        Memo: purchaseData.selectedGtin,
                        Key: "BOX_WITH_COLOR"
                    }]),
                    DiscountSubscriptions: (!purchaseData.discountId ? [] : [{
                        DiscountId: purchaseData.discountId
                    }])
                },
                Customer: {
                    EmailAddress: email.email,
                    FirstName: purchaseData.contact.firstName,
                    LastName: purchaseData.contact.lastName,
                    Address: {
                        Street: purchaseData.contact.street,
                        HouseNumber: purchaseData.contact.houseNumber,
                        PostalCode: purchaseData.contact.zip,
                        City: purchaseData.contact.city,
                        Country: purchaseData.contact.country
                    }
                }
            }

            let response = await CreateResource('api/purchases/billwerk/order/preview', request, true, GlobalConstants.BILL_HOST);
            if (response.error) {
                if (response.result == null) {
                    setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
                } else if (response.result.errorCode != null && Number(response.result.errorCode) === 24) {
                    setErrorMessage(Strings.PURCHASE_CONFIRMATION_ACTIVE_SUBSCRIPTION_ERROR.replace("{0}", email.email));
                } else {
                    setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
                }
                return;
            }
            return response;
        }

        function extractUsedComponentFromPreview(order, componentId) {
            order.MeteredComponents.forEach((component) => {
                if (component.ComponentId === componentId) {
                    setComponent(component)
                }
            });
        }

        function getDiscountDetails(order, discountId, priceData) {
            order.DiscountSubscriptions.forEach((discount) => {
                if (discount.DiscountId === discountId) {
                    GetResource('api/purchases/billwerk/discounts/' + discountId, 'application/json', {}, GlobalConstants.BILL_HOST)
                        .then(response => {
                            if (!response.error) {
                                let discount = response.result;
                                setDiscount(discount);
                                let discountedBrutto = (priceData.brutto - (priceData.brutto * discount.Effect.ReductionPercent / 100)).toFixed(2);
                                let discountedNetto = (priceData.discountedBrutto / (1 + priceData.tax)).toFixed(2);
                                let discountedTaxAmount = (priceData.discountedBrutto - priceData.discountedNetto).toFixed(2);
                                setPriceData(prevData => ({
                                        ...prevData,
                                        discountedBrutto: discountedBrutto,
                                        discountedNetto: discountedNetto,
                                        discountedTaxAmount: discountedTaxAmount
                                    })
                                )
                            }
                        });
                }
            });
        }

        function getTrialInformation(billwerkProduct) {
            if (billwerkProduct != null && billwerkProduct.trial != null) {
                const unit = billwerkProduct.trial.unit;
                const quantity = billwerkProduct.trial.quantity;

                const d = new Date();

                //Calculate the new date
                if (unit === 'day') {
                    d.setDate(d.getDate() + quantity);
                } else if (unit === 'week') {
                    d.setDate(d.getDate() + 7 * quantity);
                } else if (unit === 'month') {
                    d.setDate(d.getDate() + 31 * quantity);
                } else if (unit === 'year') {
                    d.setDate(d.getDate() + 365 * quantity);
                }

                return {
                    trialDate: transformStringToReadableDate(d.toISOString()),
                    trialDuration: quantity,
                    trialUnit: unit
                };
            }
            return null;
        }

        loadProduct(purchaseData.planVariantId).then(r => {
            if (queryParams.get("error") != null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            }
            setProduct(r);
            let priceData = calculatePrices(r, purchaseData)
            setPriceData(priceData);
            previewOrder().then(r => {
                if (!r.error) {
                    let preview = r.result;
                    setOrderPreview(preview);
                    if (preview?.RecurringFee?.LineItems[0]?.PeriodStart) {
                        setFirstPaymentDate(transformStringToReadableDate(preview.RecurringFee.LineItems[0].PeriodStart));
                    }
                    if (preview?.RecurringFee?.FeePeriod?.unit) {
                        setFeePeriodUnitName(ConvertFeePeriodUnitName(preview.RecurringFee.FeePeriod.unit));
                    } else {
                        setFeePeriodUnitName("Monatlich");
                    }

                    if (purchaseData.selectedColor) {
                        extractUsedComponentFromPreview(r.result, purchaseData.componentId);
                    }
                    if (purchaseData.discountId) {
                        getDiscountDetails(r.result, purchaseData.discountId, priceData);
                    }
                }
            });
        });

        loadBillwerkProduct(purchaseData.planVariantId).then(r => {
            setBillwerkProduct(r);
            let trialInformation = getTrialInformation(r);
            setTrialInformation(trialInformation);
        });

        //check if customer has already a trial period has used, in this case we don't offer another one
        loadSubscriptionContracts().then(r => {
            let trial = true;
            if (Array.isArray(r)) {
                for (let i = 0; i < r.length; i++) {
                    let phases = r[i].Phases;
                    if (phases != null && Array.isArray(phases) && phases.length > 0) {
                        for (let j = 0; j < phases.length; j++) {
                            if (phases[j].Type != null && phases[j].Type === "Trial") {
                                trial = false;
                            }
                        }
                    }
                }
            }
            setHasTrial(trial);
        })

    }, [history]);

    if (new URLSearchParams(window.location.search).get("paymentFinished") === "1") {
        //Finalize the payment
        let purchaseData = JSON.parse(sessionStorage.getItem('ls.purchase')) != null ?
            JSON.parse(sessionStorage.getItem('ls.purchase')) : {payment: {channelSecrets: {}}, contact: {}};
        const onSuccess = () => {
            if (purchaseData.selectedColor) {
                window.location.href = "https://info.tiger.media/dein-kauf-war-erfolgreich/";
            } else {
                window.location.href = "https://tiger.media/pages/vielen-dank-fur-deinen-kauf";
            }
        };
        const onError = (...args) => {
            SendTeamsNotification("finalize", purchaseData.orderId, purchaseData.planVariantId, args).then(r => r);
            setPaymentError(true);
        }
        billwerkJSWrapper.finalize(onSuccess, onError);
        return null;
    }

    const submitOrder = async (e) => {
        e.preventDefault();
        setLoading(true);

        //Create the request for the purchase service
        if (!orderDataIsValid()) {
            return;
        }
        let request = {
            Cart: {
                PlanVariantId: purchaseData.planVariantId,
                MeteredUsages: (!purchaseData.selectedColor ? [] : [{
                    ComponentId: purchaseData.componentId,
                    Quantity: 1,
                    Memo: purchaseData.selectedGtin
                }]),
                DiscountSubscriptions: (!purchaseData.discountId ? [] : [{
                    DiscountId: purchaseData.discountId
                }])
            },
            Customer: {
                EmailAddress: email.email,
                FirstName: purchaseData.contact.firstName,
                LastName: purchaseData.contact.lastName,
                Address: {
                    Street: purchaseData.contact.street,
                    HouseNumber: purchaseData.contact.houseNumber,
                    PostalCode: purchaseData.contact.zip,
                    City: purchaseData.contact.city,
                    Country: purchaseData.contact.country
                }
            }
        }

        let response = await CreateResource('api/purchases/billwerk/order/submit', request, true, GlobalConstants.BILL_HOST);
        if (response.error) {
            if (response.result == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            } else if (response.result.errorCode != null && Number(response.result.errorCode) === 24) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_ACTIVE_SUBSCRIPTION_ERROR.replace("{0}", email.email));
            } else {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_UNKNOWN_ERROR);
            }
            setLoading(false);
            return;
        }
        let order = response.result;
        let value = 0;
        if (purchaseData.selectedColor) {
            if (component) {
                value += component.PricePerUnit;
            }
        }
        value += billwerkProduct.period.quantity * priceData.brutto;
        if (discount) {
            let discountValue = discount.Duration.quantity * (priceData.brutto - priceData.discountedBrutto)
            value -= discountValue;
        }
        value = Math.round(value * 100) / 100;

        let billwerkOrder = {OrderId: order.orderId, Currency: order.currency};
        purchaseData.orderId = order.orderId;
        purchaseData.value = value;
        purchaseData.currency = order.currency;
        purchaseData.billwerkProductName = product.billwerkName;
        sessionStorage.setItem('ls.purchase', JSON.stringify(purchaseData));

        //Billwerk pay request
        if (!purchaseDataIsValid()) {
            return;
        }
        let paymentData = {};
        if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT) {
            paymentData = {
                bearer: 'Debit:Mollie',
                accountHolder: purchaseData.paymentData.accountHolder.trim(),
                iban: purchaseData.paymentData.iban.replace(/\s/g, ""),
                bic: purchaseData.paymentData.bic.replace(/\s/g, "")
            }
        } else if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_CREDIT_CARD) {
            paymentData = {
                bearer: 'CreditCard:Mollie',
            }
        } else if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_PAYPAL) {
            paymentData = {
                bearer: 'PayPal',
            }
        }

        const onError = (data) => {
            SendTeamsNotification("paySignupInteractive", purchaseData.orderId, purchaseData.planVariantId, data).then(r => r);
            setLoading(false);
            history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");
            setPaymentErrorMessage(data.errorMessage);
            setPaymentError(true);
        }

        const onSuccess = (data) => {
            const url2fa = data.Url;
            if (url2fa) {
                window.location.href = url2fa;
            } else {
                if (purchaseData.selectedColor) {
                    window.location.href = "https://info.tiger.media/dein-kauf-war-erfolgreich/";
                } else {
                    window.location.href = "https://tiger.media/pages/vielen-dank-fur-deinen-kauf";
                }
            }
        }
        billwerkJSWrapper.paySignupInteractive(paymentData, billwerkOrder, onSuccess, onError);
    }

    const purchaseDataIsValid = () => {
        if (purchaseData.paymentMethod === GlobalConstants.PAYMENT_METHOD_DEBIT) {
            if (purchaseData.paymentData == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "10"));
                return false;
            } else if (purchaseData.paymentData.accountHolder == null || purchaseData.paymentData.iban == null || purchaseData.paymentData.bic == null) {
                setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "11"));
                return false;
            }
        }
        return true;
    }

    const orderDataIsValid = () => {
        if (purchaseData == null) {
            //Purchase data is not set
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "7"));
            return false;
        } else if (purchaseData.planVariantId == null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "8"));
            return false;
        } else if (purchaseData.contact == null) {
            setErrorMessage(Strings.PURCHASE_CONFIRMATION_VALIDATION_ERROR.replace("{0}", "9"));
            return false;
        }
        return true;
    }

    function calculatePrices(product, purchaseData) {
        //Default
        let tax = 0.19;
        let unit = "€";
        if (purchaseData != null && purchaseData.contact != null && purchaseData.contact.country != null) {
            if (purchaseData.contact.country === "DE") {
                tax = Number(GlobalConstants.TAX_DE);
            } else if (purchaseData.contact.country === "AT") {
                tax = Number(GlobalConstants.TAX_AT);
            } else if (purchaseData.contact.country === "CH") {
                tax = Number(GlobalConstants.TAX_CH);
                unit = "CHF";
            }
        }

        //Calculation and formatting
        let brutto = (product.price).toFixed(2);
        let netto = (product.price / (1 + tax)).toFixed(2)
        let taxAmount = (brutto - netto).toFixed(2);

        return {brutto: brutto, tax: taxAmount, netto: netto, unit: unit}
    }

    const getPaymentMethodName = (method) => {
        switch (method) {
            case GlobalConstants.PAYMENT_METHOD_CREDIT_CARD:
                return Strings.PURCHASE_PAYMENT_METHOD_CC;
            case GlobalConstants.PAYMENT_METHOD_PAYPAL:
                return Strings.PURCHASE_TICKET_PAYMENT_METHOD_PAYPAL;
            default:
                return Strings.PURCHASE_SUBSCRIPTION_PAYMENT_METHOD_DEBIT;
        }
    }

    const onChangeStep = (step) => {
        switch (step) {
            case 0:
                history.push("/" + GlobalConstants.APP_PATH + "bundles/colors?planVariantId=" + purchaseData.planVariantId + "&componentId=" + purchaseData.componentId + (purchaseData.discountId ? "&discountId=" + purchaseData.discountId : ''));
                return;
            case 1:
                setShowLoginHint(true);
                return;
            case 2:
                history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/payment");
                return;
            case 3:
                //Active step -> Do nothing
                return;
            case 4:
                if (purchaseData.maxStep > 3) {
                    history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation");
                }
                return;
            default:
                return;
        }
    }

    function replacePLaceholders(description) {
        if (description) {
            description = description.replace(`{color}`, purchaseData.selectedColor);
        }
        return description;
    }

    if (loading) {
        return <TigerSpinner/>
    }

    return (
        <>
            {purchaseData &&
                <div className="purchase-container">
                    <PurchaseStatus step={3} maxStep={purchaseData ? purchaseData.maxStep : 1}
                                    bundled={!!purchaseData?.selectedColor}
                                    onChangeStep={(step) => onChangeStep(step)}
                    />
                    <div className="purchase-info"
                         dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_INFO}}/>
                    <div className="purchase-form-container">
                        <div className="purchase-form">
                            {purchaseData?.selectedColor &&
                                <>
                                    <div className="purchase-confirmation-section">
                                        {Strings.PURCHASE_CONFIRMATION_BUNDLE_NAME} {discount && ' – ' + discount.InternalName}
                                    </div>
                                    <div>
                                        {/* the following part comes from database */}
                                        <ul className="purchase-confirmation-list" style={{marginBottom: 0}}
                                            dangerouslySetInnerHTML={{__html: replacePLaceholders(product?.checkoutDescription)}}/>

                                        <ul className="purchase-confirmation-list">
                                            {trialInformation &&
                                                <li>{Strings.PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_TRIAL.replace("{0}", trialInformation.trialDate)}</li>
                                            }
                                        </ul>
                                        <ul style={{listStyleType: "none"}}>
                                            {orderPreview && component && priceData &&
                                                <>
                                                    <hr/>
                                                    <li style={{fontWeight: "bold"}}>
                                                        {Strings.PURCHASE_CONFIRMATION_BUNDLE_SETUP_FEE}
                                                        <span
                                                            style={{float: "right"}}>{component.PricePerUnit + " " + priceData.unit}</span>
                                                    </li>
                                                    <hr/>
                                                </>
                                            }
                                            {discount &&
                                                <>
                                                    <li style={{fontWeight: "bold"}}>
                                                        {trialInformation && trialInformation.trialUnit === 'month' ?
                                                            Strings.PURCHASE_CONFIRMATION_DISCOUNTED_PER_MONTH_WITH_TRIAL
                                                                .replace("{0}", trialInformation.trialDuration + 1)
                                                                .replace("{1}", trialInformation.trialDuration + discount.Duration.quantity)
                                                            :
                                                            Strings.PURCHASE_CONFIRMATION_DISCOUNTED_PER_MONTH.replace("{0}", discount.Duration.quantity)}
                                                        <span
                                                            style={{float: "right"}}>{priceData?.discountedBrutto + " " + priceData?.unit}</span>
                                                    </li>
                                                    <hr/>
                                                </>
                                            }
                                            <li style={{fontWeight: "bold"}}>
                                                {(discount ? Strings.PURCHASE_CONFIRMATION_DISCOUNTED : "") + feePeriodUnitName}
                                                <span
                                                    style={{float: "right"}}>{priceData?.brutto + " " + priceData?.unit}</span>
                                            </li>
                                            <hr/>
                                        </ul>

                                    </div>
                                </>
                            }
                            {!purchaseData.selectedColor &&
                                <>
                                    <div className="purchase-confirmation-section">
                                        {Strings.PURCHASE_CONFIRMATION_PRODUCT_DATA} {discount && ' – ' + discount.InternalName}
                                    </div>
                                    <div>
                                        <ul className="purchase-confirmation-list" style={{marginBottom: 0}}
                                            dangerouslySetInnerHTML={{__html: replacePLaceholders(product?.checkoutDescription)}}/>

                                        <ul className="purchase-confirmation-list">
                                            {trialInformation && !hasTrial &&
                                                <li style={{
                                                    paddingLeft: "10px",
                                                    paddingTop: "10px"
                                                }}>{Strings.PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_TRIAL.replace("{0}", trialInformation.trialDate)}</li>
                                            }
                                        </ul>


                                        <ul style={{listStyleType: "none"}}>
                                            <hr/>
                                            {discount &&
                                                <>
                                                    <li style={{fontWeight: "bold"}}>
                                                        {trialInformation && !hasTrial && trialInformation.trialUnit === 'month' ?
                                                            Strings.PURCHASE_CONFIRMATION_DISCOUNTED_PER_MONTH_WITH_TRIAL
                                                                .replace("{0}", trialInformation.trialDuration + 1)
                                                                .replace("{1}", trialInformation.trialDuration + discount.Duration.quantity)
                                                            :
                                                            Strings.PURCHASE_CONFIRMATION_DISCOUNTED_PER_MONTH.replace("{0}", discount.Duration.quantity)}
                                                        <span
                                                            style={{float: "right"}}>{priceData?.discountedBrutto + " " + priceData?.unit}</span>
                                                    </li>
                                                    <hr/>
                                                </>
                                            }
                                        </ul>
                                        <div style={{fontWeight: "bold"}}>
                                            {trialInformation && hasTrial ?
                                                Strings.PURCHASE_SUBSCRIPTION_CONFIRMATION_PRODUCT_TRIAL.replace("{0}", trialInformation.trialDate)
                                                :
                                                Strings.PURCHASE_SUBSCRIPTION_FIRST_PAYMENT.replace("{0}", firstPaymentDate)
                                            }
                                            <span
                                                style={{float: "right"}}>{priceData?.brutto + " " + priceData?.unit}</span>
                                            <hr/>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="purchase-confirmation-section">
                                {Strings.PURCHASE_CONFIRMATION_ACCOUNT}
                            </div>
                            <div>
                                <ul style={{listStyleType: "none"}}>
                                    <li>{email.email}</li>
                                </ul>
                            </div>
                            <div className="purchase-confirmation-section">
                                {Strings.PURCHASE_CONFIRMATION_PAYMENT_METHOD}
                                <div className="purchase-confirmation-edit"
                                     onClick={() => history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/payment")}>
                                    {Strings.PURCHASE_CONFIRMATION_EDIT}
                                </div>
                            </div>
                            <div>
                                <ul style={{listStyleType: "none"}}>
                                    <li>{getPaymentMethodName(purchaseData.paymentMethod)}</li>
                                </ul>
                            </div>
                            <div className="purchase-confirmation-section">
                                {Strings.PURCHASE_CONFIRMATION_INVOICE}
                                <div className="purchase-confirmation-edit"
                                     onClick={() => history.push("/" + GlobalConstants.APP_PATH + "purchase/subscription/address")}>
                                    {Strings.PURCHASE_CONFIRMATION_EDIT}
                                </div>
                            </div>
                            <div>
                                <ul style={{listStyleType: "none"}}>
                                    <li>{purchaseData.contact.firstName + " " + purchaseData.contact.lastName}</li>
                                    <li>{purchaseData.contact.street + " " + purchaseData.contact.houseNumber}</li>
                                    <li>{purchaseData.contact.zip + " " + purchaseData.contact.city}</li>
                                    <li>{purchaseData.contact.country === "DE" ? Strings.PURCHASE_COUNTRY_DE :
                                        (purchaseData.contact.country === "AT" ? Strings.PURCHASE_COUNTRY_AT : Strings.PURCHASE_COUNTRY_CH)}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="purchase-info purchase-info-checkbox">
                            <div className={"round"}>
                                <input name="agb" id="agb" type="checkbox" value={agb} checked={agb}
                                       onChange={(e) => setAgb(!agb)}/>
                                <label htmlFor="agb"></label>
                            </div>
                            {purchaseData.selectedColor &&
                                <label className="roundlabel" htmlFor="agb"
                                       dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_BUNDLE_AGB}}/>
                            }
                            {!purchaseData.selectedColor &&
                                <label className="roundlabel" htmlFor="agb"
                                       dangerouslySetInnerHTML={{__html: Strings.PURCHASE_CONFIRMATION_AGB}}/>
                            }
                        </div>
                        <div className="purchase-form">
                            <TigerButton className="w-100" type="button" onClick={(e) => submitOrder(e)} variant="red"
                                         disabled={!agb}>
                                {loading ?
                                    <Spinner animation="border"/>
                                    :
                                    Strings.PURCHASE_CONFIRMATION_SUBMIT_BUTTON}
                            </TigerButton>
                        </div>
                    </div>

                    <InfoModal
                        title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                        text={errorMessage}
                        show={errorMessage != null}
                        onHide={() => setErrorMessage(null)}/>

                    <InfoModal
                        title={Strings.PURCHASE_PAYMENT_ERROR_TITLE}
                        text={paymentErrorMessage ? paymentErrorMessage : Strings.PURCHASE_PAYMENT_ERROR_BODY}
                        show={paymentError}
                        onHide={() => setPaymentError(false)}/>

                    <ConfirmationModal
                        title={Strings.PURCHASE_REAUTHENTICATE_TITLE}
                        text={Strings.PURCHASE_REAUTHENTICATE_HINT}
                        actionButtonLabel={Strings.PURCHASE_REAUTHENTICATE_BUTTON}
                        actionButtonVariant="danger"
                        onActionPress={() => {
                            localStorage.removeItem("ls.token");
                            sessionStorage.removeItem("ls.purchase");
                            history.push("/" + GlobalConstants.APP_PATH + "purchase/" + purchaseData.planVariantId + '?selectedColor=' + (purchaseData.selectedColor ? purchaseData.selectedColor : '') + "&componentId=" + (purchaseData.componentId ? purchaseData.componentId : '') + (purchaseData.discountId ? "&discountId=" + purchaseData.discountId : '') + (purchaseData.selectedGtin ? "&selectedGtin=" + purchaseData.selectedGtin : ''))
                        }}
                        show={showLoginHint}
                        onHide={() => setShowLoginHint(false)}
                    />
                </div>
            }
        </>
    )
}